/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
[class^=ant-]::-ms-clear,
[class*= ant-]::-ms-clear,
[class^=ant-] input::-ms-clear,
[class*= ant-] input::-ms-clear,
[class^=ant-] input::-ms-reveal,
[class*= ant-] input::-ms-reveal {
  display: none;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #f9b361 !important;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: none !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #f9b361 !important;
  border-right-width: 1px !important;
}
.ant-tabs-ink-bar {
  position: absolute;
  background: #f9b361 !important;
  pointer-events: none;
  color: #f9b361 !important;
}
.ant-tabs-tab-remove:active {
  color: #f9b361 !important;
}.ant-tabs-tab:hover {
  color: #f9b361 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f9b361 !important;
  text-shadow: 0 0 0.25px currentColor;
}
.ant-tag-blue-inverse {
  color: #fff;
  background: #f9b361 !important;
  border-color: #f9b361 !important;
}
.ant-tabs-nav {
  margin:0 !important;
}