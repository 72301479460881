
no-scroll {
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
    background-attachment: fixed;


}  
.objektVideo {
    width: 100%;
    height: 56vh;
}
p {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    white-space: pre-line;

}

*::-webkit-media-controls-panel {
    display: none!important;
    -webkit-appearance: none;
}
  
  
*::-webkit-media-controls-play-button {
    display: none!important;
    -webkit-appearance: none;
}
  
  
*::-webkit-media-controls-start-playback-button {
    display: none!important;
    -webkit-appearance: none;
}
.textbox1 ol li{
    font-weight: bold;
    
}

.textbox1 img{
    width: 30vw;
}

* {
    box-sizing: border-box;
    margin: 0;
  }

  input[type=text], select, textarea {
    width: 100%;
    padding: 12px;
    border:none;
    margin-bottom: 1.5rem;
    background-color: white;
    color:black;
    resize: vertical;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }
  input[type=text]:focus {
    outline: none;
    
  }

  #omossgrid {
    text-align: center;
    justify-content: center;
    align-items: center;
    /*background-color:  #f5f5f5;*/
  }
  label {
    padding: 12px 12px 12px 0;
  }
  .maklare{
      display: grid;
      text-align: center;
      justify-content: center;
      align-items: center;
  }
  .maklare img{
    transition: all 0.5s;
  }

  .maklare img:hover{
    filter: brightness(70%);
    transition: all 0.5s;
  }
  #maklaregrid {
      width:calc(50vw + 0.5rem);
      grid-template-columns: 1fr 1fr 1fr;
  }
  .font-serif {
      font-family: Source Serif Pro;
  }
  
  .btnfilter {
    background-color: #f9b361;
    color: white;
    padding: 6px 20px;
    border: none;
    cursor: pointer;
  }
  .objectFacts p {
      letter-spacing: 0.5px;
  }
  .maklaregrid {
    background-color: white;
    /* width:calc(60vw + 0.5rem);*/
    display: grid;
    padding: 5rem;
    row-gap: 2rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    grid-template-columns: 35rem 35rem;
    grid-template-rows: 50rem;
    padding-bottom: 10rem;
    padding-top: 0rem
  }
  input[type=submit] {
    background-color: #f9b361;
    color: white;
    padding: 12px 20px;
    border: none;
    cursor: pointer;
    border-radius: 0;
    width: 20vw;
  }
  
  input[type=submit]:hover {
    background-color: rgba(136, 112, 112, 0.3);
  }
 
  .formmodal {
    position: absolute; /* Sit on top of the page content */
    width: 100%; 
    height: fit-content; 
    min-height: 100vh;
    left: 0;
    right: 0;
    bottom: 0;
    top:0;
    background-color: rgba(0,0,0,0.9); 
    z-index: 8; 
    text-align: center;


  }
  .formmodalcontent {
    padding:5rem;
    

    top: 6rem;
    width: 100vw;
    height: auto;
  }
  .smoothText {
      position: absolute;
      top:45%;
      left:50%;
      transform: translate(-50%,-50%);
      width: 100vw;

  }
  .smoothText h2  {
    font-size: 3.5rem;
    text-align: center;
    color:white;
    font-family: 'Nimbus Sans D OT', sans-serif;
    letter-spacing: 3px;
    text-transform: uppercase;                                            
  }
  .smoothText h6  {
    font-size: 2em;
    text-align: center;
    color:white;
    font-family: 'Nimbus Sans D OT', sans-serif;
    letter-spacing: 3px;
    text-transform: uppercase;                                            
  }
  .formcontainer {
    width: 50rem !important;
    text-align: center;
    border-radius: 6px;
    padding: 20px;
    
  }
  .formcontainer label {
      margin-left: 0.5rem;
  }
  
  .col-25 {
    margin-top: 6px;
  }
  
  .col-75 {
    margin-top: 6px;
  }
  .fullimg {
      width: 50vw;
      height: 100%;
      object-fit: cover;

  }
  
  .imggrid5 {
      height: 25rem;
  }
  .imgleft {
    margin-left: calc(-50vw + 100%);
  }
  .imggrid5col {
    height: 100%;
    position: relative;
}
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }


main{
margin-top: 6rem;
}
img {
    pointer-events: none;
}
.maklare img {
    width: 20rem;
}

.grid_bilder{
    grid-template-columns: auto ;
    width: 100vw;
    padding: 2rem;
    display: grid;
    background-color: white;
    column-gap: 1rem;
    row-gap: 1rem;
}
.grid_bild {
    height: auto;
    max-height: 60vh;
    transition: all 0.5s;
    overflow: hidden;
}
.grid_bild img {

    width: 100%;
    height: 100%;
    object-fit: contain;   
    transition: all 0.5s;


}


.grid_bild2 {
    position: relative;
    height: 30vh;
    transition: all 0.5s;
    overflow: hidden;
}
.grid_bild2 img {

    width: 100%;
    height: 100%;
    object-fit: cover;   
    transition: all 0.5s;


}
.slideshow {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9;
}
.slideshow div {
    position: absolute;
    height: 90%;
    width: 75%;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.slideshow div img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.arrow {
    cursor: pointer;
    border: solid white;
    border-width: 0 6px 6px 0;
    display: inline-block;

    padding: 6px;
    transition: all 0.5s;

  }

.arrow:hover {
    border: solid #f9b361;
    border-width: 0 6px 6px 0;
    transition: all 0.5s;

}

  .right {
    transform: rotate(-45deg);
    position: absolute;
    top:50%;
    right:10%;
    z-index: 9;

    -webkit-transform: rotate(-45deg);
  }
  
  .left {
    transform: rotate(135deg);
    position: absolute;
    top:50%;
    left:10%;
    z-index: 9;
    -webkit-transform: rotate(135deg);
  }
.cols2 {
    -webkit-column-count: 2; /* Chrome, Safari, Opera */
	-moz-column-count:    2; /* Firefox */
	column-count:         2;
  	-webkit-column-gap:   20px; /* Chrome, Safari, Opera */ 
	-moz-column-gap:      20px; /* Firefox */
	column-gap:           20px;
	white-space:pre-wrap;
    -webkit-column-break-inside:avoid;
    -moz-column-break-inside:avoid;
    -o-column-break-inside:avoid;
    -ms-column-break-inside:avoid;
}
header {
    letter-spacing: 3px;
    text-transform: capitalize;
    z-index: 5;
    width:100%;
    margin:0;
    display: inline-block;
    height:30rem;
    position: absolute;
    transition: all 0.4s;
    font-size: 1.2rem;
    color:white;
    font-family: 'Source Sans Pro', sans-serif;

}
header h1 {
    color:white;
    font-size: 1.4rem;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
}
header h2 {
    color:white;
    font-size: 1.3rem;
    font-weight: 300;

    font-family: 'Source Sans Pro', sans-serif;
}
.submenu1 {
    border-radius: 6px;
    position:absolute;
    text-align: left;

    padding-bottom: 1rem;
    text-transform: lowercase;
    text-transform: capitalize;
}
.submenu1 div {
    
    pointer-events: all;


}
.submenu1 div:hover{
    transform: translateY(-0.15rem) !important;
}

.col {
    flex: 1 1 0 !important;
    width: 0 !important;
}

.hamburger-toggle {
        display: none;
}
   
.main3 {
    position: relative;
    background: white;
  

}

.imgcont {
    position: relative;
    width: 100%;
    height:50vh;
}
.imgcont img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.logo img {
    width:12rem;
    height: auto;

}

.logo{
    opacity: 1;
    position: absolute;
    margin-left: 3rem;
    margin-top: 1.7rem;
    z-index: 6;
    
}
.search {
    position: absolute;
    right:2rem;
    top:1.8rem;
    z-index: 3;
}
#searchbar {
    width: 100%;
    text-align: center;
    
    height: 32px;
    border-radius: 2px;
    border:1px solid #d9d9d9;
    margin-bottom: 0rem;
    background-color: white;
    resize: vertical;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    
}
#searchbar:hover {
    border:1px solid #f9b361;

}
nav {
    text-align: center;
    text-align: center;   
 

}
*{
    scroll-behavior: smooth;
}
.arrowdiv {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border: solid white;
    border-width: 0 6px 6px 0;
    padding: 14px;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    position: relative;
    z-index: 1;

}

.headline{
    color: black;
    font-size: 2rem;
}

.rykande {
    background-color: #3c3c3c; 
}


.main1 p {
    font-size: 1.2rem;

}


.grid_individ {
    margin-top: 4rem;
    display: grid;
    margin-bottom: 4rem;
}
.arrowdiv:hover{
    transition-duration: 0.5s;
    border: solid #f9b361
    ;
    border-width: 0 6px 6px 0;
    transition-timing-function: ease;

}

#text_individ {
    margin:0;
    padding: 0;
}
.overlay {
    box-sizing: unset;
    text-transform: none;
    z-index: 1;
    justify-content: center;
    text-align: center;
    
    display: block;
    position: absolute; 
    letter-spacing: 3px;
    color: white;
    font-family: -apple-system-subheadline, BlinkMacSystemFont, sans-serif;
    letter-spacing: 5px;
    text-align: center; 
    

}
.overlay h1 {
    font-size: 3.5rem;
}
h2 {
    font-family: 'Source Serif Pro', serif;

}
h1 {
    font-family: 'Source Serif Pro', serif;

}
.filterW {
    width: 30vw !important;
}
.content-block-content2{
    padding-top: 0.5rem;
    background-color: white;

}
.props3 {
    width:calc(60vw + 0.5rem);
    transition: all 0.5s;
    filter:brightness(80%);


    
}

.fastighetgrid {
    width: 100vw;
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr ;
    background-color: white;
    z-index: 2;
    column-gap: 1rem;
    row-gap: 1rem;
}
.fastighet {
    transition: all 0.5s;
    padding-bottom: 1.8rem;
    overflow: hidden;
}
.fastighet img {
    width: 100%;
    height: 40vh;
    object-fit: cover;   
    transition: all 0.5s;


}
.fastighet2 {
    transition: all 0.5s;
    padding-bottom: 1.8rem;
    height: 40vh;

    overflow: hidden;
}
.fastighet2 img {
    width: 100%;
    height: 40vh;
    object-fit: cover;   
    transition: all 0.5s;


}
.fastighetimg2 {
    position: relative;
    overflow: hidden;
    transition: transform 0.5s ;


}
.fastighetimg2:hover {
    transform: scale(1.05);
    transition: transform 0.5s ;

}
.fastighetimg3 {
    position: relative;
    overflow: hidden;
    transition: transform 0.5s ;


}

.fastighetimg {
    position: relative;
    height: 40vh;
    overflow: hidden;

}

.teammem {
    width: 100%;
    margin: auto;
}
.teamimg {
    width: 100%;
    position: relative;
    height: 25rem;
    overflow: hidden;
}
.teamimg img {
    width: 100%;
    object-fit: cover;

    height: 100%;
}
.meminfo {
    margin-top: 0.5rem;
    display: block;
    
    height: 8rem;
}
.meminfo h3 {
    font-size: 1.3rem;
    margin: 0;
    font-weight: lighter;

    color: black
    
}
.meminfo h2 {
    font-weight: 300;
    margin:0;

    font-size: 1.6rem;
    margin-top: 0.2rem;
}
.meminfo p {
    font-size: 1.2rem;
    font-weight: lighter;
    padding-top: 1rem;
    color: black;
    
}
.fastighet:hover img {
    transform: scale(1.05);
    transition: all 0.5s;

}


.fastighetinfo {
    margin-top: 0.5rem;
    display: block;
    
    height: 8rem;
}
.fastighetinfo h3 {
    font-size: 1.3rem;
    margin: 0;
    font-weight: lighter;

    color: black
    
}
.fastighetinfo h2 {
    font-weight: 300;
    margin:0;

    font-size: 1.6rem;
}
.fastighetinfo p {
    margin:0;

    font-size: 1.2rem;
    font-weight: lighter;
    color: black;
    
}
.fastighetinfo2 {
    position: absolute;
    bottom:-10px;
    left:20px;
    margin-top: 0.5rem;
    display: block;
    
    height: 8rem;
}
.fastighetinfo2 h3 {
    font-size: 1.3rem;
    margin: 0;
    font-weight: lighter;

    color: white;
    
}
.fastighetinfo2 h2 {
    font-weight: 300;
    margin:0;
    color: white;

    font-size: 1.6rem;
}
.fastighetinfo2 p {
    margin:0;

    font-size: 1.2rem;
    font-weight: lighter;
    color: white;
    
}
.props3:hover {
    transition: all 0.5s;

    filter:brightness(100%);
}


#myBtn {
    position: relative;
    transition: .5s;
    z-index: 1;
    border: none;
    margin-top: 1rem;
    color: white;
    padding: 15px 32px;
    text-align: center;

    background-color: transparent;
    border: 2px solid white;
    text-decoration: none;
    cursor: pointer;

    display: inline-block;
    font-size: 16px;
}
.btn2 {
    position: relative;
    transition: .5s;
    z-index: 1;
    border: none;
    margin-top: 1rem;
    color: white;
    padding: 15px 32px;
    text-align: center;
    background-color: #f9b361; 
    border: 2px solid white;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
}

header li {
    margin:0;
    display: inline-block;
    padding-left: 10px;
    color:white;
}
/*.media-center {
    z-index: 2;
    width:100%;
    height:65rem;
    top: 8rem;
    overflow:hidden;
    position:absolute;
    margin-top: -30rem;


    left:0;
}*/
video {
    width: 200rem;
    filter: brightness(0.95)
    
}
li {
    font-size: 20px;
    font-family: 'Source Serif Pro', serif;
}
ul {
    list-style-position: inside;

    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;

}
.spainIm {
    width: 100% !important;
    height: 500px;
    object-fit: cover;
}
#myBtn:hover {
    border: solid 2px transparent;
    transition: .5s;
    color: white;
    background-color: #f9b361;
    transform: scale(1.1);

}
.main1 {
    
    background-color: black;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: relative;
    max-height: 100vh;

    overflow: hidden;
}

.main1 video {
    width: 100vw;
    height: 100vh;
    object-fit:cover;
    position: fixed;
    /*filter:blur(200px);*/
    filter: brightness(45%);
    top: 0;
    left: 0;
 
  
    
}
.ant-dropdown-menu {
    box-shadow: none !important;
    background: rgba(72,68,68,1) !important;
    color: white !important;
}
.ant-dropdown-menu-item {
    color: white !important;
}

.topleft {
    position: absolute;
    top: 0;
    left: 16px;
  }
  
  .bottomleft {
    position: absolute;
    bottom: 0;
    left: 16px;
  }
  
  .middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
.latestprops {
    
    
    width: 100%;
    height: 100%;
    position: absolute;
    top: 43rem;
}
.content-block {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    
    
}

.ritningar {
    position: absolute;
    top:0;
    left:0;
    opacity: 1;
    width: 100%;
    height: 100%;
    


}

.propwrapper {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows:2fr 2.5fr;
    z-index: 2;
    background-color: white;
    row-gap: 0.5rem;
    padding-top: 2rem;

    column-gap: 0.5rem;
}
.gridimages {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    row-gap: 0.5rem;
    z-index: 2;
    column-gap: 0.5rem;
    margin-bottom: 0.5rem;

    
}
.plats {
    display: inline-block;
    font-size: 2vh;
    /*new:*/
    line-height: 1px;
    height: -100px;
    margin:0.1rem;
    
}
.adress {
    font-size: 3vh;
    margin:0;
    color:white;
    width: 100%;
    padding:0;
}

.info {
    text-align: left;
    
    position: absolute;
    top: 0;
    padding: 1rem;
    background-color: rgba(0,0,0,0.6);
}

.info2 {
    z-index: 1;
    pointer-events: none;
    color: white;
    text-align: center;
    position: absolute;
    left: 50%;
    width: 100%;
    top: 50%;
    font-family: 'Source Sans Pro', sans-serif;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


#maklaremain {
    transition: filter 0.5s ease;

    height: 80vh;
    background-color: rgba(123,106,88,1);

}
#maklaremain video {
    position: relative;
    
    height: 80vh;
}
#maklaremain img {
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
    filter: brightness(80%);
    
}
#sellmain {
    background-color: rgba(123,106,88,1);
    /*height: 50vh;*/
    height: 80vh;

}
#sellmain video {
    position: relative;
    /*height: 50vh;*/

    height: 80vh;
}
#sellmain1 {
    background-color: rgba(123,106,88,1);
    height: 80vh;
}
#sellmain1 video {
    position: relative;
    height: 80vh;
}

#overlay3 {
    margin:0;
}
.semer {
    position: absolute;
    background: rgba(0,0,0,0.5);
    opacity: 0;
    z-index: 10;
    color: white;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    top:50%;
    left:50%;
    transition: all 0.5s;


}
.semer:hover {
    opacity: 1;
    transition: all 0.5s;
}
.semer p {
    text-transform: uppercase;
    position: absolute;
    transform: translate(-50%, -50%);
    top:45%;
    left:50%;
    font-size: 20px;
}
.semer2 {
    position: absolute;
    background: rgba(0,0,0,0.5);
    opacity: 0;
    z-index: 5;
    color: white;
    width: 50vw;
    margin-left: 1.5rem;
    height: 100%;
    top:0%;
    left:0%;
    transition: all 0.5s;


}
.semer2:hover {
    opacity: 1;
    transition: all 0.5s;
}
.semer2 p {
    text-transform: uppercase;
    position: absolute;
    transform: translate(-50%, -50%);
    top:45%;
    left:50%;
    font-size: 20px;

}
.semer3 {
    position: absolute;
    background: rgba(0,0,0,0.5);
    opacity: 0;
    z-index: 5;
    color: white;
    width: 50vw;
    margin-left: calc(-50vw + 100% - 1.5rem);
    height: 100%;
    top:0%;
    left:0%;
    transition: all 0.5s;


}
.semer3:hover {
    opacity: 1;
    transition: all 0.5s;
}
.semer3 p {
    text-transform: uppercase;
    position: absolute;
    transform: translate(-50%, -50%);
    top:45%;
    left:50%;
    font-size: 20px;

}

.semer4{
    position: absolute;
    background: hsla(0,0%,100%,.5);
    opacity: 0;
    z-index: 10;
    color: black;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    top:50%;
    left:50%;
    transition: all 0.5s;


}

.semer4 p {
    text-transform: uppercase;
    position: absolute;
    transform: translate(-50%, -50%);
    top:45%;
    left:50%;
    font-size: 20px;
}

.rok {
    font-size: 1.2rem;
    font-weight: lighter;
    padding-left: 3rem;
    padding-right: 3rem;
}

.propwrapper > div {
    position: relative;


    color: white;
    font-size: 30px;
    text-align: center;
    font-family: 'Source Sans Pro', sans-serif;

    
    transition: .5s;

}
.propwrapper > div:hover {
      transition: .5s;

}
li a {
    margin:0;
    text-decoration: none;
    color: white;
    margin-left: 3rem;
    font-size: 1.2rem;
    transition: all 0.5s;

    display: block;
    font-family: 'Source Sans Pro', sans-serif;

}
a {
    text-decoration: none;
    color: unset !important;
}
header nav li a:hover {
    transition: all 0.5s;
    transform: translateY(-0.15rem);
    color: white;

}
.card_individ {
    border-radius: 6px;
    margin-bottom: 3rem;
    overflow: hidden;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 3rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;


}
.card_individ div {
    padding-right:3rem;


}
.card_individ img {
  width:200px;
  min-height: 100%;
}

.textbox2 {
    width: 30vw;
    text-align: left;
    overflow-wrap: break-word;
    font-family: 'Source Sans Pro', sans-serif;

}
.textbox2 h2 
{
    font-weight: 600;
}
.textbox2 h4 {
    font-weight: 600;
}
.textbox1 {
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center;
}



.textbox1 h2 {
    margin:0;
    padding:0;
    letter-spacing: 2px;
    

    /*font-family: 'Source Serif Pro', serif;*/

}
.envCont ul {
    list-style-position: inside;

    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
    padding: 0;
}
.envCont {
    margin: auto;
    
}
.textbox1 p {
    text-align: left;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 22px;
    letter-spacing: .7px;
    
    
}

header div img {
    margin-top: -20px;
}

.maklareinfo {
    text-align: center;
}


/*
Color codes
#3c3c3c
#a97927
#ffffff

*/



.props1 {
    width: 100%;
    height: 100%;
    object-fit: cover;   
    transition: all 0.5s;
}
#slideshow-example {
    overflow:hidden;
    transition: all 0.5s;
}



#slideshow-example:hover {
    transition: all 0.5s;
}
.gridimage {
    height: 40vh;
    transition: all 0.5s;
    position: relative;
    

}
.gridimage:hover {
    transition: all 0.5s;
}

.gridimage img:hover {
    transition: all 0.5s;
    transform: scale(1.05);
}
.gridimage img {
    filter: brightness(30%);
    transition: all 0.5s;

}
.gridimagecont {
    overflow: hidden;
    height: 100%;
}
.imagecont {
    min-height: 100%;
    object-fit: cover;
}


.props2 {   
    width:100%;
    height: 100%;
    object-fit: cover;
}
.grid_3 {
    grid-template-columns: auto auto auto;
}
.grid_2 {
    column-gap: 3rem;
    grid-template-columns: auto auto;
    display: grid;
}

.margin_bottom_3 {
    margin-bottom:3rem;
}
.margin_top_3 {
    margin-top:3rem;
}
.margin_top_8 {
    margin-top: 8rem;
}
.standard_width {
    width: calc(60vw + 0.5rem);

}
.textContainer {
    inline-size: 60%;
    margin: auto;
}
.close {
    display: block;
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    z-index: 10;
    opacity: 1;
    color: white;
  }
  .close:hover {
    opacity: 0.5;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: white;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }


/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 6; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content/Box */
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
  }
  

/*the container must be positioned relative:*/
.custom-select {
    position: relative;
  }
  
  .custom-select select {
    display: none; /*hide original SELECT element:*/
  }
  
  .select-selected {
    background-color: white;
  }
  
  /*style the arrow inside the select element:*/
  .select-selected:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
  }
  
  /*point the arrow upwards when the select box is open (active):*/
  .select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
  }
  
  /*style the items (options), including the selected item:*/
  .select-items div,.select-selected {
    color: black;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
    user-select: none;
  }
  
  /*style items (options):*/
  .select-items {
    position: absolute;
    background-color: white;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
  }
  
  /*hide the items when the select box is closed:*/
  .select-hide {
    display: none;
  }
  
  .select-items div:hover, .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
  }
/* Style the tab */
.tab {
    overflow: hidden;
  }
  
  /* Style the buttons inside the tab */
  .tab button {
    margin-top: 2rem;

    margin-right: 3rem;
    margin-left: 3rem;
    
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;

    font-size: 17px;
  }
  .tab div a {
      color: white;
      text-decoration: none;
      cursor: pointer;
  }
  .tablinks {
      color: black;
  }
  .tab div {
    margin-top: 2rem;

    margin-right: 3rem;
    margin-left: 3rem;

    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;

    font-size: 17px;
  }
.submenupad {
    padding-top: 1rem;
}


.navbarObjekt{
    position: absolute;

    bottom:0;
    background-color: rgba(0, 0, 0, 0.6);
    font-family: 'Source Sans Pro', sans-serif;
    height: 2.5rem;
    width: 100%;
    z-index: 3;
    

}

.navbarObjektText{
    font-weight: 300;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 1.15rem;

    transition: 250ms all ease-in-out;
}

.navbarObjektText a:hover {
    color: #f9b361 !important;
}
a:hover{
    
    color: #f9b361;
}
.hoverSpain {
    opacity: 1;
    filter:unset;

}


.faktawrapper {
    margin: 0 auto;
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    -moz-column-gap: 10px;
    -webkit-column-gap: 10px;
    column-gap: 10px;

}
.faktawrapper div {
    display: inline-block;
    overflow: hidden;
    width: 100%;

    word-wrap: break-word;

}
.modalFinish {
    position: absolute;
    
}

  @media(min-width:1390px) {
      
    .faktawrapper {
        max-width: 1140px !important;

    }
  }
  @media(max-width:576px) {
    .faktawrapper {
        margin: 0 auto;
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
        -moz-column-gap: 10px;
        -webkit-column-gap: 10px;
        column-gap: 10px;
    }
    .faktawrapper div {
        display: block;

    }
  }

  @media (max-width: 800px) {

    .slideshow div {
        position: absolute;
        width: 85%;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }


    
    .arrow:hover {
        border: solid #f9b361;
        border-width: 0 6px 6px 0;
        transition: all 0.5s;
    
    }
    
      .right {
        transform: rotate(-45deg);
        position: absolute;
        top:50%;
        right:3%;
        z-index: 9;
    
        -webkit-transform: rotate(-45deg);
      }
      
      .left {
        transform: rotate(135deg);
        position: absolute;
        top:50%;
        left:3%;
        z-index: 9;
        -webkit-transform: rotate(135deg);
      }
    input[type=submit] {
        
        width: 50vw;
      }
      .checkContainer {
          margin:unset;
          width: 100%;
      }
      
      .formmodalcontent {
        top: 0rem;
        padding:2rem;
        padding-top:0.7rem;
        
    }
      
    .overlay h1 {
        font-size: 2.3rem;
    }

    .form-inline input {
      margin: 10px 0;
    }
    
    .form-inline {
      flex-direction: column;
      align-items: stretch;
    }
  }
  @media (max-height:660px) {
    .overlay h1 {
        font-size: 2.3rem;
    }
  }


  @media (max-width: 1000px) {
    .textContainer {
        inline-size: unset;
    }
    .formcontainer {
        width: 100% !important;
    }
    


  }
  @media (max-width: 1000px) {

    .logo {
        left:7rem;
        transform: translateX(-50%);
        margin-left:0;
    }
    .hamburger-toggle {
        cursor: pointer;
        position: absolute;
        right: 1rem;
        top: 1.8rem;
        display: block;
        justify-content: center;
        align-items: center;
        height: 4rem;
        width: 4rem;
        z-index: 6;
        color: white;
        font-weight: 700;
        letter-spacing: 0.15rem;
       
        }
      
        .hamburger-toggle {
        overflow-y: hidden;
        position: absolute;   
     }
    
        header .row {
            display: block;
        }

        header nav {
            
            margin: 0;
            width: 100%;
            backdrop-filter: blur(15px);
            max-height: 200vh;
            overflow-x: hidden;
            background-color: rgba(0,0,0,0.6);
            opacity: 0;
            pointer-events: none;
            transition: 300ms all ease-in-out;
            align-items: center;
            text-align: center;
            

            
        }
        header {
            position: absolute;
    
        }
        header nav ul li {
         display: block;
         padding:0;
    
         
        }
        
        li a {
            margin: 0px;
            
        }
        header ul {
            padding:0;
            margin-top: 10rem;
        }
        
        header nav.active {
            opacity: 1;
            pointer-events: all;
            
            
        }
        header nav div {
            font-size: 2rem;
        }
        .submenu1 {
            border-radius: 0px;
            text-align: center;
            position: relative;
            padding:0;
            padding-top:0.5rem;
            padding-bottom: 0.5rem;
            margin: 0;
        }
        .submenupad {
        padding:0;
    }
    .newNav {
        margin-top: 5rem;
        padding-right: unset !important;
        padding-bottom: 6rem;
    }
    }
    
@media (max-width: 774px) {
    
    #reel {
        display: none;
    }
    .fullwidth {
        max-width: unset !important;
    }
    
}
  @media (max-width: 1000px) {
    .filterW {
        width: 100% !important;
    }

    .maklaregrid {
        background-color: white;
        width:100vw;
        display: grid;
        padding: 2rem;
        row-gap: 4rem;
        text-align: center;
        grid-template-columns: auto;
      }
    

    .grid_bilder {
        grid-template-columns: auto ;
        width: 100vw;


    }
    .grid_bild {
        height: auto;
        max-height: 80vh;
        
    }
    .grid_bild img{
        width: 100%;
        height: 100%;
        object-fit: scale-down;
    }

    h3 {
        font-size: 2rem;
    }

    #card_maklare_objekt {
        margin-top: 3rem;
        grid-row: 2;
    }
    .grid_3 {
        grid-template-columns: auto;
    }
    .grid_2 {
        grid-template-columns: auto;

    }
    
    .fastighetgrid {
        padding:1rem;
        grid-template-columns: 1fr;
    }
    .gridimage {
        width: unset;

    }
    .gridimagecont {
        width: 100%;
    }
    .props3 {
        width:100%;

    }
    
    .gridimages {
        grid-template-columns: auto;
        row-gap: 0;
        margin-bottom: 0;

    }
    .gridimage img:hover{
        transform: scale(1)
    }
    .propwrapper {
        grid-template-rows:2fr 8.5fr;

    }
    
  }


  @media(max-width:767px) {
    .fullimg {
        width: 100vw;
        height: 100%;
        object-fit: cover;
  
    }
    
    .imggrid5 {
        height: auto;
    }
    .imgleft {
      margin-left: unset;
    }
    .imggrid5col {
        height:auto;
        position: relative;
        margin-bottom: 20px;
    }
   
    .imggrid5col h1  {
        padding-left: 15px;
        padding-right: 15px;
    }
    .imggrid5col p {
        padding-left: 15px;
        padding-right: 15px;
    }
  .semer2 {
      display: none;
  }
  .semer3 {
      display: none;
  }
  .hideLine {
      display: none;
  }


.hamburger-toggle {
    top:2rem;
    right:0rem;
}
  }